import React from "react";

import ConseilCard from "../card/conseilCard/conseilCard";

import "./blocConseil.scss";

export default function BlocConseil({
  president,
  vicePresidents,
  secretary,
  listConseil,
}) {
  return (
    <section className="bloc-conseil">
      <div className="bloc-conseil__administration">
        <div className="administration-content">
          <ConseilCard
            fullName={`${president.Prenom} ${president.Nom.toUpperCase()}`}
            department={president.Departement}
            photo={president.Photo}
            title="Président"
          />

          {vicePresidents.map((vicePresident, i) => {
            return (
              <ConseilCard
                key={i}
                fullName={`${
                  vicePresident.Prenom
                } ${vicePresident.Nom.toUpperCase()}`}
                department={vicePresident.Departement}
                photo={vicePresident.Photo}
                title="Vice-président"
              />
            );
          })}
          {secretary && (
            <ConseilCard
              fullName={`${secretary.Prenom} ${secretary.Nom.toUpperCase()}`}
              department={secretary.Departement}
              photo={secretary.Photo}
              title="Secrétaire"
            />
          )}
        </div>
        <div className="bloc-conseil__other">
          {listConseil.map((person, i) => {
            return (
              <div key={i} className="bloc-conseil__person">
                <span className="bloc-conseil__name">{person.Nom}</span>
                <div>
                  <span className="bloc-conseil__title">{person.Poste}</span>
                  {person.Region ? " - " : ""}
                  <span className="bloc-conseil__department">
                    {person.Region}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
